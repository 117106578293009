import React, { Fragment, Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Canvas } from 'react-three-fiber'
import { Physics } from 'use-cannon'
import Worker from "worker-loader!./worker/worker.js"
import { AnimatedAsset } from './AnimatedAsset'
import './App.css'
import { Benches } from './Benches'
import { Building } from './Building'
import { MobileControls } from './components/MobileControls'
import { Flora } from './Flora'
import { InfoCard } from './InfoCard'
import { CITY_MAP, MISCELLANEOUS } from './landscape/map'
import { detectMobile } from './lib/helper'
import { Lights } from './lights/Lights'
import { Loader } from './Loader'
import { Me } from './Me'
import { Mountains } from './Mountains'
import { GrayPath, GrayPath2, GrayPath3, LifePath } from './Path'
import { Pin } from './Pin'
import { Plane } from './Plane'
import { Project } from './projects/Projects'
import { StreetLights } from './StreetLights'
import { Temple } from './Temple'
import { Unit } from './Unit'

const POSITION_THRESHOLD = 0.05 // don't perform checks if position is close to the last position

function App() {
  const [mePos, setMePos] = useState([])
  const [target, setTarget] = useState('')
  const worker = useRef()

  const lastMePos = useRef({ x: 0, z: 0 })
  const handlePos = useCallback((p) => setMePos(p), [])
  const [x, , z] = mePos

  const mobileControls = useMemo(() => {
    const isMobile = detectMobile()
    return isMobile && <MobileControls />
  }, [])

  const setFromWorker = useCallback((e) => {
    setTarget(e.data)
  }, [])

  useEffect(() => {
    worker.current = new Worker()
    worker.current.onmessage = setFromWorker
  }, [])

  useEffect(() => {
    if (Math.abs(x - lastMePos.current.x) > POSITION_THRESHOLD
      || Math.abs(z - lastMePos.current.z) > POSITION_THRESHOLD) {
      worker.current.postMessage({ x, z })
      lastMePos.current.x = x
      lastMePos.current.z = z
    }
  }, [x, z])


  return (
    <Fragment>
      <Canvas shadowMap colorManagement
        gl={{
          powerPreference: "high-performance",
          antialias: true,
        }}
        pixelRatio={Math.min(window.devicePixelRatio - 1, 2)}
        camera={{ position: [-18, 18, 16], fov: 70 }}>
        <color attach="background" args={['white']} />
        <Lights />
        <Physics
          gravity={[0, -40, 0]}
          iterations={10}
          tolerance={10}
          defaultContactMaterial={{
            friction: 0.9,
            restitution: 0.7,
            contactEquationStiffness: 1e8,
            contactEquationRelaxation: 20,
            frictionEquationStiffness: 1e7,
            frictionEquationRelaxation: 2,
          }}
        >
          <Plane rotation={[-Math.PI / 2, 0, -1.57]} />
          <Me updatePosition={handlePos} />
          <Unit number={255} />
          <Mountains number={3} />
          <Flora />
          <Suspense fallback={<mesh></mesh>}>
            <Building url='/static/scene/hospital.gltf' {...CITY_MAP.HOSPITAL} />
            <Building url='/static/scene/uni.gltf' {...CITY_MAP.UNI} />
            <AnimatedAsset url='/static/scene/anim-plane.gltf' {...CITY_MAP.PLANE}
              animate={target === 'PLANE'}
              loop={true}
              timeScale={2} />
            <Building url='/static/scene/uni2.gltf' {...CITY_MAP.EDI_UNI} />
            <Building url='/static/scene/kpv.gltf' {...CITY_MAP.D2P} />
            <Building url='/static/scene/mailbox.gltf' {...CITY_MAP.MAILBOX} />
            <AnimatedAsset url='/static/scene/anim-eve.gltf' {...CITY_MAP.EVE}
              animate={target === 'EVE'}
              timeScale={1.5} />
            <Building url='/static/scene/house.gltf' {...CITY_MAP.HOUSE} />
            <Building url='/static/scene/school.gltf' {...MISCELLANEOUS.SCHOOL} />
            <Building url='/static/scene/sport.gltf' {...MISCELLANEOUS.SPORT} />
            <Temple {...MISCELLANEOUS.TEMPLE} />
            <Project isActive={target === 'TABLEE'} url='/static/scene/textures/tablee.jpg' {...CITY_MAP.TABLEE} />
            <Project isActive={target === 'DAILY_DOSED'} url='/static/scene/textures/daily-dosed.jpg' {...CITY_MAP.DAILY_DOSED} />
            <StreetLights />
            <Benches />
          </Suspense>
        </Physics>
        <LifePath />
        <GrayPath />
        <GrayPath2 />
        <GrayPath3 />
        <Suspense fallback={<mesh></mesh>}>
          <Pin target={target} />
        </Suspense>
      </Canvas>
      {/* <Timeline mePos={mePos} /> */}
      <InfoCard target={target} />
      {mobileControls}
      <Loader />
    </Fragment>
  )
}

export default App
