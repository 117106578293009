import React, { Fragment, useEffect } from 'react'
import { useBox, useCylinder } from 'use-cannon'

const TREES = 23

const TREE_BRANCHES = [
  [2.5, 7, 17.5],
  [2.5, 7, 32.5],
  [30, 7, 40],
  [30, 7, 45],
  [40, 7, 36],
  [40, 7, 29],
  [60, 7, 28],
  [60, 7, 37],

  [71, 7, 45],
  [72, 7, 42.5],
  [73, 7, 45],

  [80, 7, 58],
  [80, 7, 67],
  [80, 7, 79],
  [80, 7, 86],
  [80, 7, 90],
  [80, 7, 95],

  [52, 7, 63.5],
  [53, 7, 61],
  [54, 7, 63.5],

  [62, 7, 73.5],
  [63, 7, 71],
  [64, 7, 73.5],

]

const TREE_TRUNKS = [
  [2.5, 5.2, 17.5],
  [2.5, 5.2, 32.5],
  [30, 5.2, 40],
  [30, 5.2, 45],
  [40, 5.2, 36],
  [40, 5.2, 29],
  [60, 5.2, 28],
  [60, 5.2, 37],

  [71, 5.2, 45],
  [72, 5.2, 42.5],
  [73, 5.2, 45],

  [80, 5.2, 58],
  [80, 5.2, 67],
  [80, 5.2, 79],
  [80, 5.2, 86],
  [80, 5.2, 90],
  [80, 5.2, 95],

  [52, 5.2, 63.5],
  [53, 5.2, 61],
  [54, 5.2, 63.5],


  [62, 5.2, 73.5],
  [63, 5.2, 71],
  [64, 5.2, 73.5],
]

export function Flora() {
  return (
    <Fragment>
      <TreeTrunks count={TREES} />
      <TreeBranches count={TREES} />
    </Fragment>
  )
}

export function TreeBranches({ count }) {
  const [ref, api] = useCylinder(() => ({
    mass: 0.1,
    args: [0, 0.9, 2.4, 16],
    position: [0, 0, 0],
    type: 'Static'
  }))

  useEffect(() => {
    TREE_BRANCHES.forEach((treePosition, index) => {
      api.at(index).position.set(...treePosition)
    })
    ref.current.matrixAutoUpdate = false
    ref.current.updateMatrix()
  }, [api])

  return (
    <instancedMesh castShadow matrixAutoUpdate={false} ref={ref} args={[null, null, count]} >
      <coneBufferGeometry attach="geometry" args={[0.9, 2.4, 23]} />
      <meshStandardMaterial attach="material" color="#2de329" roughness={1} metalness={0} />
    </instancedMesh>
  )
}

export function TreeTrunks({ count }) {
  const [ref, api] = useBox(() => ({
    mass: 0.1,
    args: [0.5, 1.8, 0.5],
    position: [0, 0, 0],
    type: 'Static'
  }))

  useEffect(() => {
    TREE_TRUNKS.forEach((trunkPosition, index) => {
      api.at(index).position.set(...trunkPosition)
    })
    ref.current.matrixAutoUpdate = false
    ref.current.updateMatrix()
  }, [api])

  return (
    <instancedMesh castShadow matrixAutoUpdate={false} ref={ref} args={[null, null, count]} >
      <cylinderBufferGeometry attach="geometry" args={[0.3, 0.3, 1]} />
      <meshStandardMaterial attach="material" color="#b06f07" roughness={0.5} metalness={0} />
    </instancedMesh>
  )
}
