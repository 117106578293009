import React, { useCallback, useEffect, useRef } from 'react'
import './MobileControls.css'

const RADIUS = 85

export function MobileControls() {
  const thumbRef = useRef()
  const joystickRef = useRef()
  const offset = useRef({ x: 0, y: 0 })

  useEffect(() => {
    const { x, y, width, height } = thumbRef.current.getBoundingClientRect()

    offset.current.x = x + width * 0.5
    offset.current.y = y + height * 0.5
  }, [])

  const move = useCallback((e) => {
    const { clientX, clientY } = e.touches[0]
    const dx = clientX - offset.current.x
    const dy = clientY - offset.current.y

    const dxSign = Math.sign(dx)
    const dySign = Math.sign(dy)

    const dxScaled = Math.min(dx * dxSign, RADIUS)
    const dyScaled = Math.min(dy * dySign, RADIUS)

    const distance = Math.hypot(dx, dy)

    const scalar = distance > RADIUS ? RADIUS / distance : 1

    joystickRef.current.style.transform = `scale(0.9)`
    thumbRef.current.style.transition = ``
    thumbRef.current.style.transform = `translate3d(${dx * scalar}px,${dy * scalar}px,0) scale(1.3)`

    let vector = [0, 0, 0, 0, 0]

    const dyNorm = dyScaled / RADIUS
    const dxNorm = dxScaled / RADIUS


    let vx, vy

    if (dy > 0 && dx > 0) {
      [vx, vy] = rotate(0, 0, dxNorm, dyNorm, 45)
    } else if (dy > 0 && dx < 0) {
      [vx, vy] = rotate(0, 0, dxNorm, dyNorm, -45)
    } else if (dy < 0 && dx > 0) {
      [vx, vy] = rotate(0, 0, dxNorm, dyNorm, -45)
    } else if (dy < 0 && dx < 0) {
      [vx, vy] = rotate(0, 0, dxNorm, dyNorm, 45)
    }

    if (dy > 0) {
      vector[1] = vy
    } else {
      vector[0] = vy
    }

    if (dx > 0) {
      vector[3] = vx
    } else {
      vector[2] = vx
    }

    window.vVector = vector
  }, [])

  const getBack = useCallback((e) => {
    thumbRef.current.style.transition = `250ms ease`
    thumbRef.current.style.transform = `translate3d(0,0,0) scale(1)`
    joystickRef.current.style.transform = `scale(1)`
    window.vVector = [0, 0, 0, 0, 0]
  }, [])

  const handleJump = useCallback((e) => {
    e.preventDefault()
    let value

    if (e.type === 'touchend') {
      value = 0

      if (joystickRef.current.style.transform === `scale(0.85)`) {
        joystickRef.current.style.transform = `scale(1)`
      }
      thumbRef.current.style.background = `rgba(10,10,10,0.7)`
    } else {
      value = 1

      thumbRef.current.style.background = `red`
      joystickRef.current.style.transform = `scale(0.85)`
    }

    window.vVector = [0, 0, 0, 0, value]
  }, [])

  const stopPropagation = useCallback((e) => {
    e.stopPropagation()
  }, [])


  useEffect(() => {
    window.vVector = [0, 0, 0, 0, 0]
  }, [])

  return (
    <div className="mobile-controls" onTouchStart={handleJump} onTouchEnd={handleJump}>
      <div className="joystick" ref={joystickRef} onTouchStart={stopPropagation} onTouchMove={move} onTouchEnd={getBack}>
        <div className="thumb" ref={thumbRef}>
        </div>
      </div>
    </div>
  )
}

function rotate(cx, cy, x, y, angle) {
  var radians = (Math.PI / 180) * angle,
    cos = Math.cos(radians),
    sin = Math.sin(radians),
    nx = (cos * (x - cx)) + (sin * (y - cy)) + cx,
    ny = (cos * (y - cy)) - (sin * (x - cx)) + cy
  return [nx, ny]
}
