import React, { useEffect, useMemo, useRef } from 'react'
import { useFrame, useLoader } from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { CITY_MAP } from './landscape/map'

export function Pin({ target }) {
  const { nodes, scene } = useLoader(GLTFLoader, '/static/scene/pin.gltf')
  useMemo(() => Object.values(nodes).forEach(obj =>
    obj.isMesh && Object.assign(obj, { castShadow: true })), [nodes])

  const yPos = useRef(0)

  useEffect(() => {
    if (scene && target) {
      const [x, , z] = CITY_MAP[target].position
      yPos.current = CITY_MAP[target].size[1]
      scene.visible = true
      scene.position.set(x, 0, z)
    } else if (scene && !target) {
      scene.visible = false
    }
  }, [scene, target])

  useFrame((t) => {
    if (target) {
      if (scene) {
        scene.position.y = yPos.current + 0.7 * Math.sin(2.4 * t.clock.elapsedTime)
        scene.rotation.z += 0.015
      }
    }
  })

  return <primitive object={scene} position={[-1, -1, -1]} rotation={[-Math.PI / 2, 0, 0]} />
}
