import React, { useEffect, useMemo } from 'react'
import { useLoader } from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { useBox } from 'use-cannon'

export function Building({ url, ...rest }) {
  const { nodes, scene } = useLoader(GLTFLoader, url)
  useMemo(() => Object.values(nodes).forEach(obj => {
    obj.isMesh && Object.assign(obj, { castShadow: true })
  }), [nodes])


  const [ref] = useBox(() => ({
    mass: 0.1,
    args: rest.size,
    position: rest.position,
    rotation: rest.rotation,
    type: 'Static'
  }))

  useEffect(() => {
    ref.current.matrixAutoUpdate = false
    ref.current.updateMatrix()
  }, [])

  return <primitive ref={ref} object={scene} {...rest} />
}
