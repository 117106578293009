import { useProgress } from "drei"
import React, { useEffect, useRef, useState } from 'react'
import './Loader.css'

export function Loader() {
  const { progress } = useProgress()
  const [loaded, setLoaded] = useState(false)
  const ref = useRef()
  const loadingBarRef = useRef()

  useEffect(() => {

    if (!loaded) {
      if (progress > 97) {
        setLoaded(true)
        window.setTimeout(() => {
          ref.current.classList.add('do-hide')
        }, 850)
      }
    }
    loadingBarRef.current.style.width = `${progress}px`
  }, [progress])

  return (
    <div ref={ref} className="loading-page">
      <div className="loading-bar-container">
        <div className="loading-bar" ref={loadingBarRef}>
          <span className="loading-data">{progress.toFixed(1)}%</span>
        </div>
      </div>
    </div>
  )
}
