import React from 'react'
import './Icons.css'

export function BabyIcon() {
  return (<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlxlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 512 512" style={{ enableBackground: 'new 0 0 512 512' }} xmlSpace="preserve">
    <g>
      <g>
        <path d="M145.612,311.133c-0.005-0.009-0.057-0.098-0.063-0.108c-2.911-4.977-9.279-6.614-14.259-3.711
     c-4.981,2.903-6.645,9.332-3.749,14.316c0.005,0.009,0.057,0.098,0.063,0.108c1.937,3.312,5.405,5.145,8.977,5.145
     c1.795,0,3.616-0.462,5.282-1.434C146.844,322.546,148.507,316.118,145.612,311.133z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M327.626,243.908c-0.601-5.74-5.739-9.904-11.479-9.304c-10.653,1.115-21.079-2.576-28.627-10.124
     s-11.238-17.983-10.124-28.628c0.601-5.739-3.564-10.879-9.304-11.479c-5.739-0.604-10.878,3.566-11.479,9.304
     c-1.773,16.942,4.106,33.555,16.132,45.581c10.616,10.616,24.806,16.441,39.642,16.441c1.97,0,3.952-0.103,5.935-0.311
     C324.061,254.788,328.226,249.647,327.626,243.908z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M325.327,168.725c-0.6-5.738-5.722-9.916-11.479-9.304c-3.155,0.33-6.257-0.764-8.498-3.005
     c-2.24-2.239-3.334-5.336-3.004-8.497c0.601-5.74-3.565-10.879-9.304-11.479c-5.751-0.597-10.879,3.565-11.479,9.304
     c-0.99,9.458,2.295,18.735,9.01,25.45c5.93,5.931,13.853,9.186,22.139,9.185c1.098,0,2.205-0.057,3.311-0.173
     C321.762,179.604,325.928,174.465,325.327,168.725z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M375.56,218.957c-0.6-5.738-5.737-9.907-11.479-9.304c-3.163,0.33-6.258-0.764-8.499-3.006
     c-2.239-2.238-3.333-5.335-3.003-8.496c0.602-5.74-3.565-10.879-9.304-11.479c-5.756-0.602-10.879,3.565-11.479,9.304
     c-0.99,9.458,2.295,18.735,9.009,25.448c5.93,5.931,13.855,9.186,22.14,9.186c1.098,0,2.204-0.057,3.311-0.173
     C371.995,229.837,376.161,224.697,375.56,218.957z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M423.622,88.377c-0.039-0.04-0.079-0.077-0.119-0.116l0.001-0.001c-0.04-0.04-0.081-0.075-0.12-0.114
     c-25.365-25.243-59.025-39.143-94.822-39.143c-35.909,0-69.67,13.984-95.062,39.375c-52.415,52.416-52.415,137.704,0,190.121
     c14.326,14.326,31.317,25.018,49.854,31.619c-38.089-2.887-73.505-19.102-100.798-46.396c-4.08-4.077-10.695-4.079-14.778,0
     c-4.08,4.08-4.08,10.697,0,14.778c34.168,34.169,79.599,52.987,127.922,52.986c48.014-0.001,93.168-18.587,127.262-52.343
     c0.218-0.216,0.442-0.426,0.659-0.644l0.002-0.002C476.038,226.081,476.038,140.793,423.622,88.377z M408.844,263.722
     c-0.671,0.671-1.352,1.326-2.031,1.983c-21.197,20.19-48.873,31.272-78.251,31.272c-30.327,0-58.839-11.809-80.284-33.254
     c-44.268-44.27-44.268-116.3,0-160.567c21.444-21.444,49.956-33.254,80.284-33.254c11.929,0,23.573,1.835,34.621,5.356
     c-7.461,2.815-14.308,7.197-20.115,13.004c-8.989,8.988-13.939,20.941-13.938,33.653c0,12.712,4.951,24.664,13.94,33.651
     c7.461,7.463,16.835,12.021,26.4,12.837c1.046,0.09,2.085,0.134,3.116,0.134c9.263,0,17.887-3.573,24.551-10.237
     c13.8-13.801,14.223-31.212,1.079-44.356c-4.081-4.079-10.696-4.079-14.778,0c-4.08,4.08-4.08,10.697,0,14.778
     c2.228,2.227,6.861,6.861-1.078,14.802c-3.062,3.061-6.804,4.429-11.114,4.057c-4.66-0.397-9.42-2.809-13.4-6.791
     c-10.408-10.408-10.409-27.343-0.002-37.75c13.993-13.993,36.735-14.026,50.772-0.107c0.075,0.075,0.153,0.147,0.229,0.223
     C453.112,147.424,453.112,219.453,408.844,263.722z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M88.225,368.807l-0.211-0.366c-2.955-4.955-9.384-6.6-14.338-3.645c-4.957,2.955-6.591,9.348-3.636,14.304
     c1.923,3.397,5.461,5.304,9.102,5.304c1.744,0,3.513-0.438,5.138-1.357C89.301,380.205,91.068,373.829,88.225,368.807z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M459.508,52.492C425.657,18.642,380.651,0,332.78,0c-47.872,0-92.88,18.643-126.732,52.494L52.494,206.048
     c-69.879,69.88-69.88,183.58-0.002,253.46C86.343,493.358,131.349,512,179.221,512c47.873,0,92.88-18.643,126.732-52.494
     l153.554-153.554C529.385,236.073,529.386,122.371,459.508,52.492z M444.728,291.174L291.174,444.728
     c-29.904,29.904-69.663,46.374-111.953,46.374S97.173,474.633,67.27,444.731C10.842,388.303,6.007,299.534,52.74,237.548
     c-1.007,3.469-1.934,6.967-2.734,10.505c-7.313,32.352-5.638,66.055,4.845,97.465c1.46,4.376,5.537,7.144,9.91,7.144
     c1.096,0,2.212-0.174,3.309-0.541c5.473-1.826,8.43-7.745,6.603-13.219c-18.997-56.921-5.035-118.523,36.373-161.602
     c-1.344,4.365-2.537,8.784-3.55,13.262c-7.313,32.352-5.638,66.055,4.845,97.466c1.46,4.377,5.536,7.144,9.91,7.144
     c1.096,0,2.211-0.174,3.309-0.541c5.473-1.826,8.43-7.745,6.603-13.219c-19.341-57.953-4.54-120.771,38.629-163.938
     c-0.045-0.015-0.082-0.031-0.125-0.046l50.157-50.157c29.906-29.903,69.665-46.373,111.957-46.373
     c42.29,0,82.047,16.469,111.951,46.372C506.461,129,506.46,229.444,444.728,291.174z"/>
      </g>
    </g>
  </svg>
  )
}

export function UniIcon() {
  return (
    <svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlxlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 64 64" xmlSpace="preserve">
      <path d="m62.471 24.118-30-16c-.295-.157-.646-.157-.941 0l-30 16c-.327.174-.53.513-.53.882s.203.708.529.882l8.471 4.518v12.6h2v-11.533l3 1.6v6.075c-1.721.447-3 1.999-3 3.858 0 .914.32 1.747.838 2.422-.546 1.867-.838 3.792-.838 5.74v2.838c0 .379.214.725.553.895l1.105.553c.724.361 1.533.552 2.342.552s1.618-.191 2.342-.553l1.105-.553c.339-.169.553-.515.553-.894v-2.838c0-1.948-.292-3.873-.838-5.74.518-.675.838-1.508.838-2.422 0-1.859-1.279-3.411-3-3.858v-5.008l14.529 7.749c.148.078.309.117.471.117s.323-.039.471-.117l19.529-10.416v11.039c-5.562 4.191-12.432 6.494-19.401 6.494h-1.197c-3.433 0-6.827-.545-10.089-1.62l-.625 1.9c3.462 1.141 7.068 1.72 10.713 1.72h1.197c7.574 0 15.037-2.561 21.016-7.211.243-.189.386-.48.386-.789v-12.6l8.471-4.518c.326-.174.529-.513.529-.882s-.203-.708-.529-.882zm-44.471 29.264-.553.276c-.895.447-2 .447-2.895 0l-.552-.276v-2.22c0-1.503.183-2.992.544-4.446.452.178.941.284 1.456.284s1.004-.106 1.456-.284c.361 1.454.544 2.943.544 4.446zm0-10.382c0 1.103-.897 2-2 2s-2-.897-2-2 .897-2 2-2 2 .897 2 2zm14-3.134-14.312-7.633 12.389-6.607c.569.232 1.217.374 1.923.374 2.243 0 4-1.318 4-3s-1.757-3-4-3-4 1.318-4 3c0 .457.139.883.373 1.268l-12.81 6.832-11.438-6.1 27.875-14.867 27.875 14.867zm-2-16.866c0-.408.779-1 2-1s2 .592 2 1-.779 1-2 1-2-.592-2-1z" />
    </svg>
  )
}

export function ContactIcon() {
  return (
    <svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlxlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 512 512" xmlSpace="preserve" className='scale-down'>
      <g>
        <path d="m448 0h-352c-8.284 0-15 6.716-15 15v66h-17c-8.284 0-15 6.716-15 15s6.716 15 15 15h17v50h-17c-8.284 0-15 6.716-15 15s6.716 15 15 15h17v50h-17c-8.284 0-15 6.716-15 15s6.716 15 15 15h17v50h-17c-8.284 0-15 6.716-15 15s6.716 15 15 15h17v50h-17c-8.284 0-15 6.716-15 15s6.716 15 15 15h17v66c0 8.284 6.716 15 15 15h352c8.284 0 15-6.716 15-15v-482c0-8.284-6.716-15-15-15zm-96 375c-8.284 0-15-6.716-15-15 0-39.816-24.313-81-65-81s-65 41.184-65 81c0 8.284-6.716 15-15 15s-15-6.716-15-15c0-38.11 17.399-77.596 49.26-97.756-15.433-13.034-25.26-32.509-25.26-54.244 0-39.149 31.851-71 71-71s71 31.851 71 71c0 21.735-9.827 41.21-25.26 54.244 31.862 20.161 49.26 59.64 49.26 97.756 0 8.284-6.716 15-15 15z" />
        <circle cx="272" cy="208" r="41" /></g>
    </svg>
  )
}

export function PlaneIcon() {
  return (
    <svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlxlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 512 512" xmlSpace="preserve" className='scale-down'>
      <path d="M497.535,14.465c-19.569-19.568-51.395-19.241-70.557,0.726L322.092,124.488L66.131,39.781L12.4,93.513l213.352,131.365
			L117.796,337.372l-69.231-11.366L0,374.571l101.78,35.649L137.429,512l48.565-48.565l-11.366-69.231l112.494-107.955
			L418.487,499.6l53.732-53.732l-84.706-255.961L496.808,85.022C516.776,65.86,517.103,34.034,497.535,14.465z"/>
    </svg>
  )
}

export function WorkIcon() {
  return (
    <svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlxlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 512 512" xmlSpace="preserve" className='scale-down'>
      <path d="m497.09375 60.003906c-.03125 0-.0625-.003906-.09375-.003906h-136v-15c0-24.8125-20.1875-45-45-45h-120c-24.8125 0-45 20.1875-45 45v15h-136c-8.351562 0-15 6.84375-15 15v330c0 24.8125 20.1875 45 45 45h422c24.8125 0 45-20.1875 45-45v-329.683594c0-.019531 0-.039062 0-.058594-.574219-9.851562-6.632812-15.199218-14.90625-15.253906zm-316.09375-15.003906c0-8.269531 6.730469-15 15-15h120c8.269531 0 15 6.730469 15 15v15h-150zm295.1875 45-46.582031 139.742188c-2.042969 6.136718-7.761719 10.257812-14.226563 10.257812h-84.378906v-15c0-8.285156-6.714844-15-15-15h-120c-8.285156 0-15 6.714844-15 15v15h-84.378906c-6.464844 0-12.183594-4.121094-14.226563-10.257812l-46.582031-139.742188zm-175.1875 150v30h-90v-30zm181 165c0 8.269531-6.730469 15-15 15h-422c-8.269531 0-15-6.730469-15-15v-237.566406l23.933594 71.796875c6.132812 18.40625 23.289062 30.769531 42.6875 30.769531h84.378906v15c0 8.285156 6.714844 15 15 15h120c8.285156 0 15-6.714844 15-15v-15h84.378906c19.398438 0 36.554688-12.363281 42.6875-30.769531l23.933594-71.796875zm0 0" />
    </svg>
  )
}


export function WebsiteIcon() {
  return (
    <svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlxlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 512 512" xmlSpace="preserve" className='scale-down'>
      <path d="M254,0C146.7,0,0,81.1,0,254c0,168.5,141.1,254,254,254c193.7,0,254-169.7,254-254C508,129.6,412.8,0,254,0z M195.1,23.9
			c-26.5,22.6-48.5,60-62.7,106.4c-18.4-10.9-35.3-24.4-50.3-40.1C113.1,57.7,152.3,34.9,195.1,23.9z M71.2,102.4
			c16.8,17.5,35.9,32.4,56.7,44.2c-7.8,30.3-12.4,63.9-13,99.2H16.6C18.4,193.1,37.6,142.8,71.2,102.4z M71.2,405.6
			c-33.7-40.4-52.8-90.7-54.6-143.4h98.3c0.6,35.4,5.2,68.9,13,99.2C107.2,373.3,88.1,388.1,71.2,405.6z M82.1,417.9
			c15-15.7,31.9-29.2,50.3-40.1c14.2,46.3,36.2,83.8,62.7,106.4C152.3,473.1,113.1,450.3,82.1,417.9z M245.8,491
			c-42.6-5.4-79.3-53-99.1-121.2c30.6-15.5,64.4-24.2,99.1-25.5V491z M245.8,328c-36.2,1.2-71.4,10.1-103.3,25.7
			c-6.7-28-10.7-58.9-11.3-91.5h114.6V328z M245.8,245.8H131.2c0.6-32.6,4.6-63.5,11.3-91.5c32,15.6,67.2,24.5,103.3,25.7V245.8z
			 M245.8,163.7c-34.8-1.2-68.5-10-99.1-25.5C166.5,69.9,203.2,22.4,245.8,17V163.7z M436.8,102.4c33.6,40.4,52.8,90.7,54.6,143.4
			h-98.2c-0.6-35.4-5.2-68.9-13-99.2C400.9,134.7,420,119.9,436.8,102.4z M425.9,90.1c-15,15.7-31.9,29.2-50.3,40.1
			c-14.2-46.3-36.2-83.7-62.7-106.4C355.7,34.9,394.9,57.7,425.9,90.1z M262.2,17c42.6,5.4,79.3,53,99.1,121.2
			c-30.6,15.5-64.3,24.2-99.1,25.5V17z M262.2,180c36.2-1.2,71.4-10.1,103.3-25.7c6.7,28,10.7,58.9,11.3,91.5H262.2V180z
			 M262.2,262.2h114.6c-0.6,32.6-4.6,63.5-11.3,91.5c-31.9-15.7-67.1-24.6-103.3-25.7V262.2z M262.2,491V344.3
			c34.8,1.2,68.5,10,99.1,25.5C341.5,438.1,304.8,485.6,262.2,491z M312.9,484.1c26.5-22.6,48.5-60,62.7-106.4
			c18.4,10.9,35.3,24.4,50.3,40.1C394.9,450.3,355.7,473.1,312.9,484.1z M436.8,405.6c-16.8-17.5-35.9-32.3-56.6-44.2
			c7.8-30.3,12.4-63.9,13-99.2h98.2C489.6,314.9,470.4,365.2,436.8,405.6z"/>
    </svg>
  )
}

export function ControlsIcon() {
  return (
    <svg viewBox="0 0 100 50" width="100" height="50"
      xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      className="info-path">
      <g className="rotate-this">
        <path d="M 40 50 C 75 25, 0 25, 45 0" />
      </g>
    </svg>
  )
}

export function SocialIcons() {
  return (<div className="social-icons">
    <a href="https://www.linkedin.com/in/alexandrosgounis" target="_blank"><LinkedInIcon /></a>
    <a href="https://github.com/AlexandrosGounis" target="_blank"><GithubIcon /></a>
    <a href="https://dribbble.com/gounisAlexandros" target="_blank"><DribbbleIcon /></a>
  </div>)
}

function GithubIcon() {
  return (
    <svg className="github-icon" xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24">
      <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-4.466 19.59c-.405.078-.534-.171-.534-.384v-2.195c0-.747-.262-1.233-.55-1.481 1.782-.198 3.654-.875 3.654-3.947 0-.874-.312-1.588-.823-2.147.082-.202.356-1.016-.079-2.117 0 0-.671-.215-2.198.82-.64-.18-1.324-.267-2.004-.271-.68.003-1.364.091-2.003.269-1.528-1.035-2.2-.82-2.2-.82-.434 1.102-.16 1.915-.077 2.118-.512.56-.824 1.273-.824 2.147 0 3.064 1.867 3.751 3.645 3.954-.229.2-.436.552-.508 1.07-.457.204-1.614.557-2.328-.666 0 0-.423-.768-1.227-.825 0 0-.78-.01-.055.487 0 0 .525.246.889 1.17 0 0 .463 1.428 2.688.944v1.489c0 .211-.129.459-.528.385-3.18-1.057-5.472-4.056-5.472-7.59 0-4.419 3.582-8 8-8s8 3.581 8 8c0 3.533-2.289 6.531-5.466 7.59z" />
    </svg>
  )
}

function DribbbleIcon() {
  return (
    <svg className="dribbble-icon" xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24">
      <path d="M13.01 13.188c.617 1.613 1.072 3.273 1.361 4.973-2.232.861-4.635.444-6.428-.955 1.313-2.058 2.989-3.398 5.067-4.018zm-.53-1.286c-.143-.32-.291-.638-.447-.955-1.853.584-4.068.879-6.633.883l-.01.17c0 1.604.576 3.077 1.531 4.223 1.448-2.173 3.306-3.616 5.559-4.321zm-3.462-5.792c-1.698.863-2.969 2.434-3.432 4.325 2.236-.016 4.17-.261 5.791-.737-.686-1.229-1.471-2.426-2.359-3.588zm7.011.663c-1.117-.862-2.511-1.382-4.029-1.382-.561 0-1.102.078-1.621.21.873 1.174 1.648 2.384 2.326 3.625 1.412-.598 2.52-1.417 3.324-2.453zm7.971-1.773v14c0 2.761-2.238 5-5 5h-14c-2.762 0-5-2.239-5-5v-14c0-2.761 2.238-5 5-5h14c2.762 0 5 2.239 5 5zm-4 7c0-4.418-3.582-8-8-8s-8 3.582-8 8 3.582 8 8 8 8-3.582 8-8zm-6.656-1.542c.18.371.348.745.512 1.12 1.439-.248 3.018-.233 4.734.049-.084-1.478-.648-2.827-1.547-3.89-.922 1.149-2.16 2.055-3.699 2.721zm1.045 2.437c.559 1.496.988 3.03 1.279 4.598 1.5-1.005 2.561-2.61 2.854-4.467-1.506-.261-2.883-.307-4.133-.131z" />
    </svg>
  )
}

function LinkedInIcon() {
  return (
    <svg className="linkedin-icon" xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24">
      <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
    </svg>
  )
}
