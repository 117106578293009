import { MeshWobbleMaterial } from 'drei'
import React, { Fragment, useEffect, useRef, useCallback } from 'react'
import { useFrame, useThree } from 'react-three-fiber'
import { fromEvent, merge } from 'rxjs'
import { filter } from 'rxjs/operators'
import { useSphere } from 'use-cannon'
import { KEYS, KEY_CODES } from './lib/keys'

const CONTROLS_INITIAL_STATE = {
  jump: 0
}

const JUMP_THRESHOLD = 10

export function Me({ updatePosition }) {
  const [ref, api] = useSphere(() => ({
    mass: 1,
    args: 0.5,
    position: [22.5, 10, 42.5],
  }))

  const controls = useRef(CONTROLS_INITIAL_STATE)
  const canJump = useRef(1)

  const pos = useRef([0, 0.5, 0])
  const vel = useRef([0, 0, 0])
  const keys = useRef([0, 0, 0, 0, 0])
  const { camera } = useThree()

  useFrame(() => {
    const c = camera.position

    if (window.vVector) {
      keys.current = JSON.parse(JSON.stringify(window.vVector))
      if (keys.current[4]) {
        canJump.current && jump(1300)
      } else {
        canJump.current = 1
      }
    }

    const dx = c.x * (1 - 0.05) + (pos.current[0] - 15) * 0.05
    const dy = c.y * (1 - 0.05) + (pos.current[1] + 18) * 0.05
    const dz = c.z * (1 - 0.05) + (pos.current[2] + 15) * 0.05

    camera.position.set(dx, dy, dz)

    let [fx, fy, fz] = vel.current
    fx += keys.current[0] - keys.current[1]
    fz += keys.current[3] - keys.current[2]
    api.velocity.set(fx * 0.9, fy, fz * 0.9)

    updatePosition(pos.current)
  })

  const jump = useCallback((value) => {
    if (pos.current[1] < JUMP_THRESHOLD) {
      api.applyForce([0, value, 0], [0, 0, 0])
      canJump.current = 0
    }
  }, [pos.current[1]])

  useEffect(() => {
    api.position.subscribe(p => pos.current = p)
    api.velocity.subscribe(v => vel.current = v)

    const x = merge(
      fromEvent(document, 'keydown').pipe(filter(e => KEYS.has(e.keyCode)), filter(e => !e.repeat)),
      fromEvent(document, 'keyup').pipe(filter(e => KEYS.has(e.keyCode))),
    ).subscribe(key => {
      if (key.keyCode === KEY_CODES.UP) {
        keys.current[0] = key.type === 'keyup' ? 0 : 1
      }
      else if (key.keyCode === KEY_CODES.DOWN) {
        keys.current[1] = key.type === 'keyup' ? 0 : 1
      }
      else if (key.keyCode === KEY_CODES.LEFT) {
        keys.current[2] = key.type === 'keyup' ? 0 : 1
      }
      else if (key.keyCode === KEY_CODES.RIGHT) {
        keys.current[3] = key.type === 'keyup' ? 0 : 1
      } else if (key.keyCode === KEY_CODES.SPACE) {
        if (key.type === 'keyup' || controls.current.jump > 0) {
          controls.current.jump = 0
          keys.current[4] = 0
        } else {
          jump(1000)
        }
      }
    })
    return () => x.unsubscribe()
  }, [])

  return (
    <Fragment>
      <mesh receiveShadow castShadow ref={ref}>
        <sphereGeometry attach="geometry" args={[0.5, 24, 24]} />
        <MeshWobbleMaterial attach="material" color="white" factor={20} roughness={0.1} metalness={0.7} />
      </mesh>
    </Fragment>
  )
}
