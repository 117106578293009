import React, { useEffect, useMemo, useRef } from 'react'
import { useFrame, useLoader } from 'react-three-fiber'
import { AnimationMixer, LoopPingPong } from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { useBox } from 'use-cannon'

export function AnimatedAsset({ url, ...rest }) {
  const gltf = useLoader(GLTFLoader, url)
  const mixer = useRef()
  const action = useRef()

  useMemo(() => Object.values(gltf.nodes).forEach(obj => {
    obj.isMesh && Object.assign(obj, { castShadow: true })
  }), [gltf.nodes])

  useEffect(() => {
    if (mixer.current) {
      if (rest.animate) {
        action.current.play()
      }
    } else {
      mixer.current = new AnimationMixer(gltf.scene)
      action.current = mixer.current.clipAction(gltf.animations[0])
      if (!rest.loop) {
        action.current.loop = LoopPingPong
        action.current.clampWhenFinished = true
      }
      action.current.timeScale = rest.timeScale
    }

  }, [mixer.current, rest.animate, rest.loop, rest.timeScale])




  useFrame((state, delta) => {
    if (rest.animate) {
      mixer.current && mixer.current.update(delta)
    }
  })

  const [ref] = useBox(() => ({
    mass: 0.1,
    args: rest.size,
    position: rest.position,
    rotation: rest.rotation,
    type: 'Static'
  }))

  return <primitive ref={ref} object={gltf.scene} {...rest} dispose={null} />
}
