import React, { Fragment, useMemo } from 'react'
import { DirectionalLight, Mesh, SpotLight } from 'three'

const DIRECTIONAL_LIGHTS = [
  { position: [4, 35, 35], lookAt: [-5, 0, 47] }
]

export function Lights() {
  const spotlights = useMemo(() => {
    return DIRECTIONAL_LIGHTS.map((light, index) => {
      const { position, lookAt } = light

      const instance = new SpotLight(
        'white',
        3.5,
        200,
        Math.PI / 4,
        1,
        2
      )

      instance.position.set(...position)

      const target = new Mesh()
      target.position.set(...lookAt)
      instance.target = target

      return (
        <Fragment key={index}>
          <primitive object={instance} />
          <primitive object={instance.target} />
        </Fragment>
      )
    })
  }, [])

  const directionalLight = useMemo(() => {
    const instance = new DirectionalLight(undefined, 3.5)

    instance.position.set(80, 55, 80)
    instance.castShadow = true
    instance.shadow.mapSize.width = 768
    instance.shadow.mapSize.height = 1024
    instance.shadow.camera.far = 120
    instance.shadow.camera.left = -40
    instance.shadow.camera.right = 40
    instance.shadow.bias = -0.0001
    instance.shadow.camera.top = 40
    instance.shadow.camera.bottom = -120

    // const target = new Mesh()
    // target.position.set(-8, 5, 78)
    // instance.target = target

    return (
      <Fragment>
        <primitive object={instance} />
        <primitive object={instance.target} />
      </Fragment>
    )
  }, [])


  return (
    <Fragment>
      {/* <hemisphereLight intensity={0.45} /> */}
      <ambientLight intensity={0.45} />

      {/* {streetLights} */}
      {directionalLight}
      {spotlights}

      <pointLight position={[-10, 15, -20]} color="white" intensity={0.25} />
      {/* <pointLight position={[-600, 30, -90]} color="white" intensity={0.25} /> */}
      {/* <pointLight position={[91, 15, -43]} color="red" intensity={60.25} /> */}
      <fog attach="fog" args={['white', 75, 220]} />
      {/* <rectAreaLight
        width={20}
        height={20}
        color={'#ff0051'}
        intensity={10}
        ambientLight={0.2}
        position={[40, 10, 30]}
        rotation={[-0.7, 0, 0]}
      /> */}
    </Fragment>
  )
}
