import React, { Fragment, useEffect, useMemo } from 'react'
import { useLoader } from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { useBox } from 'use-cannon'


export function Temple({ ...rest }) {
  const [ref] = useBox(() => ({
    mass: 0.1,
    args: [15, 1, 20],
    rotation: rest.rotation,
    position: [rest.position[0], rest.position[1] - 0.5, rest.position[2]],
    type: 'Static'
  }))

  useEffect(() => {
    ref.current.matrixAutoUpdate = false
    ref.current.updateMatrix()
  }, [])

  return (<Fragment>
    <mesh ref={ref} receiveShadow castShadow>
      <boxGeometry attach="geometry" args={[15, 1, 20]} />
      <meshStandardMaterial color="white" attach="material" />
    </mesh>
    <TempleUnit url='/static/scene/temple.gltf' {...rest} />
  </Fragment>)
}


export function TempleUnit({ url, ...rest }) {
  const { nodes, scene } = useLoader(GLTFLoader, url)
  useMemo(() => Object.values(nodes).forEach(obj => {
    obj.isMesh && Object.assign(obj, { castShadow: true, receiveShadow: true })
  }), [nodes])


  const [ref] = useBox(() => ({
    mass: 0.1,
    args: [10, 13, 12.5],
    position: rest.position,
    rotation: rest.rotation,
    type: 'Static'
  }))

  useEffect(() => {
    ref.current.matrixAutoUpdate = false
    ref.current.updateMatrix()
  }, [])

  return <primitive ref={ref} object={scene} {...rest} />
}
