import React, { useEffect, useMemo } from 'react'
import { BoxGeometry, BufferGeometry, CylinderGeometry, Geometry, Mesh, MeshStandardMaterial } from 'three'
import { useCylinder } from 'use-cannon'


export const LIGHT_POSITIONS = [
  { position: [37.5, 6, 42.5], rotation: [0, -3 * Math.PI / 4, 0], bulb: [38.5, 10, 37.5] },
  { position: [37.5, 6, 12.5], rotation: [0, Math.PI / 4, 0], bulb: [38.5, 10, 37.5] },
  { position: [72.5, 6, 52.5], rotation: [0, 3 * Math.PI / 4, 0], bulb: [38.5, 10, 37.5] },
  { position: [57.5, 6, 12.5], rotation: [0, -Math.PI / 4, 0], bulb: [38.5, 10, 37.5] },
  { position: [52.5, 6, 72.5], rotation: [0, Math.PI / 4, 0], bulb: [38.5, 10, 37.5] },
  { position: [52.5, 6, 52.5], rotation: [0, Math.PI / 4, 0], bulb: [38.5, 10, 37.5] },
  { position: [62.5, 6, 42.5], rotation: [0, - Math.PI / 4, 0], bulb: [38.5, 10, 37.5] },
  { position: [62.5, 6, 62.5], rotation: [0, - Math.PI / 4, 0], bulb: [38.5, 10, 37.5] },
  { position: [77, 6, 97], rotation: [0, -3 * Math.PI / 4, 0], bulb: [38.5, 10, 37.5] },
]

export function StreetLights() {
  const [ref, api] = useCylinder(() => ({
    mass: 0.1,
    args: [0.15, 0.15, 6, 16],
    position: [0, 0, 0],
    type: 'Static'
  }))

  const geometry = useMemo(() => {
    const base = new CylinderGeometry(0.15, 0.15, 6, 24)
    const pole = new CylinderGeometry(0.15, 0.15, 1.5, 24)
    const bulb = new BoxGeometry(1, 0.2, 1.2)

    const baseMesh = new Mesh(base)
    const poleMesh = new Mesh(pole)
    poleMesh.rotateX(Math.PI / 4)
    poleMesh.position.z += 0.5
    poleMesh.position.y += 3.45

    const bulbMesh = new Mesh(bulb)
    bulbMesh.position.z += 1.4
    bulbMesh.position.y += 4.1

    const geometry = new Geometry();

    [baseMesh, poleMesh, bulbMesh].forEach(mesh => {
      mesh.matrixAutoUpdate = false
      mesh.updateMatrix()
      geometry.merge(mesh.geometry, mesh.matrix)
    })

    const bufferGeometry = new BufferGeometry()
    return bufferGeometry.fromGeometry(geometry)
  }, [])

  const material = useMemo(() => {
    return new MeshStandardMaterial({ color: 'white', metalness: 0, roughness: 0 })
  }, [])

  useEffect(() => {
    LIGHT_POSITIONS.forEach((light, index) => {
      api.at(index).position.set(...light.position)
      api.at(index).rotation.set(...light.rotation)
    })
    ref.current.matrixAutoUpdate = false
  }, [])

  return <instancedMesh castShadow ref={ref} args={[geometry, material, LIGHT_POSITIONS.length]} />
}
