import React, { useEffect, useMemo } from 'react'
import { BoxGeometry, BufferGeometry, CylinderGeometry, Geometry, Mesh, MeshStandardMaterial } from 'three'
import { useBox } from 'use-cannon'


export const BENCH_POSITIONS = [
  { position: [2.5, 5.25, 22.5], rotation: [0, 0, 0] },
  { position: [2.5, 5.25, 27.5], rotation: [0, 0, 0] },
  { position: [32.5, 5.25, 2.5], rotation: [0, -Math.PI / 2, 0] },
  { position: [37.5, 5.25, 2.5], rotation: [0, -Math.PI / 2, 0] },
  { position: [92.5, 5.25, 92.5], rotation: [0, Math.PI, 0] },
  { position: [92.5, 5.25, 97.5], rotation: [0, Math.PI, 0] },
  { position: [37.5, 5.25, 97.5], rotation: [0, 0, 0] },
  { position: [37.5, 5.25, 102.5], rotation: [0, 0, 0] },
  { position: [42.5, 5.25, 107.5], rotation: [0, Math.PI / 2, 0] },
  { position: [47.5, 5.25, 107.5], rotation: [0, Math.PI / 2, 0] },
]

export function Benches() {
  const [ref, api] = useBox(() => ({
    mass: 0.1,
    args: [0.8, 1.1, 2.5],
    position: [0, 0, 0],
    type: 'Static'
  }))



  const geometry = useMemo(() => {
    const c1 = new CylinderGeometry(0.15, 0.15, 0.4, 16)
    const c2 = new CylinderGeometry(0.15, 0.15, 0.4, 16)
    const bench = new BoxGeometry(0.8, 0.2, 2.5)

    const h1 = new CylinderGeometry(0.1, 0.1, 0.4, 16)
    const h2 = new CylinderGeometry(0.1, 0.1, 0.4, 16)
    const back = new BoxGeometry(0.5, 0.2, 2.5)

    const c1Mesh = new Mesh(c1)
    const c2Mesh = new Mesh(c2)
    const benchMesh = new Mesh(bench)

    const h1Mesh = new Mesh(h1)
    const h2Mesh = new Mesh(h2)
    const backMesh = new Mesh(back)

    c1Mesh.position.z -= 0.75
    c2Mesh.position.z += 0.75

    h1Mesh.position.z -= 0.75
    h1Mesh.position.x += 0.5
    h1Mesh.position.y += 0.75

    h2Mesh.position.z += 0.75
    h2Mesh.position.x += 0.5
    h2Mesh.position.y += 0.75

    benchMesh.position.y += 0.5

    backMesh.rotateZ(Math.PI / 2)
    backMesh.position.x += 0.5
    backMesh.position.y += 1

    const geometry = new Geometry();

    [c1Mesh, c2Mesh, benchMesh, h1Mesh, h2Mesh, backMesh].forEach(mesh => {
      mesh.updateMatrix()
      geometry.merge(mesh.geometry, mesh.matrix)
    })

    const bufferGeometry = new BufferGeometry()
    return bufferGeometry.fromGeometry(geometry)
  }, [])

  const material = useMemo(() => {
    return new MeshStandardMaterial({ color: 'white', metalness: 0.7, roughness: 0.2 })
  }, [])



  useEffect(() => {
    BENCH_POSITIONS.forEach((bench, index) => {
      api.at(index).position.set(...bench.position)
      api.at(index).rotation.set(...bench.rotation)
    })
    ref.current.matrixAutoUpdate = false
    ref.current.updateMatrix()
  }, [])

  return <instancedMesh castShadow ref={ref} args={[geometry, material, BENCH_POSITIONS.length]} />
}
