import React, { useEffect } from 'react'
import { useBox } from 'use-cannon'

const SIDE = 1
const UNIT_DIMENSIONS = [SIDE, SIDE, SIDE]

const POSITIONS = [
  [42.5, 5.5, 57.5],
  [47.5, 5.5, 77.5],
  [67.5, 5.5, 67.5],
]

export function Mountains({ number }) {
  const [ref, api] = useBox(() => ({
    mass: 0.5,
    args: UNIT_DIMENSIONS,
    position: [0, 0, 0],
    // type: 'Dynamic'
  }))

  useEffect(() => {
    POSITIONS.forEach((pos, i) => api.at(i).position.set(...pos))
    ref.current.matrixAutoUpdate = false
    ref.current.updateMatrix()
  }, [api])

  return (
    <instancedMesh receiveShadow castShadow ref={ref} args={[null, null, number]}>
      <boxBufferGeometry attach="geometry" args={UNIT_DIMENSIONS} />
      <meshStandardMaterial attach="material" color="#d4d4d4" roughness={1} metalness={0.2} />
    </instancedMesh>
  )
}
