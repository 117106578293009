import { BabyIcon, ContactIcon, ControlsIcon, PlaneIcon, SocialIcons, UniIcon, WebsiteIcon, WorkIcon } from "../assets/icons/Icons"

export const CITY_MAP = {
  HOSPITAL: { position: [43.75, 5, 32.5], rotation: [0, -Math.PI, 0], size: [8, 15.5, 7.75] },
  UNI: { position: [62.5, 5, 32.5], rotation: [0, Math.PI, 0], size: [5, 17.5, 6] },
  PLANE: { position: [81, 7, 43.5], rotation: [0, -Math.PI / 4, 0], size: [3, 12, 7] },
  EDI_UNI: { position: [82.5, 5, 62.5], rotation: [0, Math.PI, 0], size: [5, 18.5, 6] },
  EVE: { position: [82.5, 7, 72.5], rotation: [0, -Math.PI / 2, 0], size: [3, 12, 2] },
  HOUSE: { position: [82.5, 5, 82.5], rotation: [0, -Math.PI / 2, 0], size: [5, 15, 4] },
  D2P: { position: [82.5, 5, 92.5], rotation: [0, -Math.PI / 2, 0], size: [5, 17, 4] },
  TABLEE: { position: [67.5, 8, 82.5], rotation: [0, -Math.PI / 7, 0], size: [5, 14, 4] },
  DAILY_DOSED: { position: [57.5, 8, 82.5], rotation: [0, -Math.PI / 7, 0], size: [5, 14, 4] },
  MAILBOX: { position: [47.5, 5, 90], rotation: [0, Math.PI / 2, 0], size: [1, 12, 1] },
}

export const MISCELLANEOUS = {
  SCHOOL: { position: [47.5, 5.01, 20.5], rotation: [0, 0, 0], size: [7, 9.5, 5] },
  SPORT: { position: [47.5, 5.01, 15.5], rotation: [0, 0, 0], size: [6, 0.75, 3.5] },
  TEMPLE: { position: [20, 6, 22.5], rotation: [0, -Math.PI / 2, 0], size: [] }
}

export const INFO_CARD = {
  HOUSE: {
    YEAR: '2019 · Tunegenie.com',
    TITLE: 'Front-end developer',
    BODY: `I worked on streaming services for radio stations | wordpress plugins & pwas`,
    KEYWORDS: ['Vue', 'Django', 'Swift', 'docker'],
    ICON: WorkIcon
  },
  D2P: {
    YEAR: '2019 - present · KPV-LAB',
    TITLE: 'Front-end developer',
    BODY: `I learned Glsl and 3D graphics on the web | jest & web workers`,
    KEYWORDS: ['React', 'GLSL', 'Three.JS', 'Jest'],
    ICON: WorkIcon
  },
  UNI: {
    YEAR: '2016',
    TITLE: 'Bachelor\'s Degree',
    BODY: 'As I wanted to become both a musician and an engineer, I started with a bachelor\'s in Music Science & Art, and I graduated in 2016',
    ICON: UniIcon
  },
  EDI_UNI: {
    YEAR: '2018',
    TITLE: 'Masters\'s Degree',
    BODY: 'There I studied Digital Signal Processing and Acoustics. I graduated from the University of Edinburgh in 2018, science and art were coming together 🎉',
    ICON: UniIcon,
  },
  EVE: {
    YEAR: '2019 · UoE & HWatt',
    TITLE: 'Robotics Internship',
    BODY: `Shortly after my graduation I designed an Android app that connects to a punchball via BLE. 
      I put an accelerometer to the punchball prototype, then collected and analysed data in the app.`,
    KEYWORDS: ['Java', 'React Native', 'Arduino'],
    ICON: WorkIcon,
  },
  HOSPITAL: {
    YEAR: '1994',
    TITLE: 'Birth Day',
    BODY: `I was born in Thessaloniki to two parents that were high school teachers.`,
    ICON: BabyIcon
  },
  PLANE: {
    YEAR: '2017',
    TITLE: 'Moved to Edinburgh',
    BODY: 'Soon I realised that I wanted to do something more scientific, so I moved to Edinburgh to pursue an M.Sc.',
    ICON: PlaneIcon
  },
  TABLEE: {
    YEAR: '2018',
    TITLE: 'Tablee Web App',
    BODY: `My very first project using the MERN stack. 
    This is a platform where you can create DSP schematics and flowcharts, but it also doubles as table management software.`,
    URL: 'https://tablee.co.uk',
    KEYWORDS: ['React', 'MongoDB', 'Firebase', 'REST'],
    ICON: WebsiteIcon
  },
  DAILY_DOSED: {
    YEAR: '2019',
    TITLE: 'Daily Dosed news api mixer',
    BODY: `DailyDosed fetches news from different sources, optimises the incoming traffic and displays story-like slider cards.
    I was making a smart mirror at the time and I wanted something to use as a screensaver.`,
    URL: 'https://dailydosed.com',
    KEYWORDS: ['React', 'React Native', 'GraphQL'],
    ICON: WebsiteIcon
  },
  MAILBOX: {
    TITLE: 'Contact',
    YEAR: 'Alexandros Gounis',
    BODY: 'Do you want to say hi? Reach me at',
    KEYWORDS: ['gounisalex@gmail.com'],
    SOCIAL: SocialIcons,
  },
  NULL: {
    YEAR: '',
    TITLE: '',
    BODY: '',
    ICON: () => { }
  }
}

export const CONTROLS_CARD = {
  DESKTOP: {
    YEAR: 'Welcome',
    TITLE: 'Follow the path',
    BODY: `Use the keyboard arrows to move the ball, press Space to jump`,
    ICON: ControlsIcon
  },
  MOBILE: {
    YEAR: 'Welcome',
    TITLE: 'Follow the path',
    BODY: `Use the joystick to move the ball, tap to jump`,
    ICON: ControlsIcon
  }
}
