import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import './InfoCard.css'
import { CONTROLS_CARD, INFO_CARD } from './landscape/map'
import { detectMobile } from './lib/helper'
import { KEYS } from './lib/keys'
import { isFirstTimeUser, saveUserToLocalStorage } from './lib/storage'

const ENTER_KEY = 13

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function InfoCard({ target }) {
  const cardRef = useRef()
  const prevProps = usePrevious({ target })

  const [info, setInfo] = useState(INFO_CARD.NULL)

  function show() {
    cardRef.current.style.opacity = '1'
    cardRef.current.style.transform = 'translate3d(0, 0, 0)'
    setInfo(INFO_CARD[target])
  }

  function hide() {
    cardRef.current.style.opacity = '0'
    cardRef.current.style.transform = 'translate3d(0, 80px, 0)'
  }

  const handleKeyDown = useCallback((e) => {
    if (KEYS.has(e.keyCode)) {
      hide()
      destroyListener()
    }
  }, [])

  const handleTouchStart = useCallback((e) => {
    hide()
    destroyListener()
  }, [])

  const handleVisit = useCallback((e) => {
    if (e.keyCode === ENTER_KEY && INFO_CARD[target]) {
      const { URL } = INFO_CARD[target]
      URL && window.open(URL, '_blank')
    }
  }, [target])

  useEffect(() => {
    if (INFO_CARD[target]) {
      document.addEventListener('keydown', handleVisit)
    }
    return () => {
      document.removeEventListener('keydown', handleVisit)
    }
  }, [target])

  const destroyListener = useCallback(() => {
    document.removeEventListener('keydown', handleKeyDown)
    document.removeEventListener('touchstart', handleTouchStart)
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('touchstart', handleTouchStart)
    if (isFirstTimeUser()) {
      const info = detectMobile() ? CONTROLS_CARD.MOBILE : CONTROLS_CARD.DESKTOP
      cardRef.current.style.opacity = '1'
      cardRef.current.style.transform = 'translate3d(0, 0, 0)'
      saveUserToLocalStorage()
      setInfo(info)
    }

    return () => {
      destroyListener()
    }
  }, [])


  useEffect(() => {
    if (prevProps) {
      const nullToTarget = !prevProps.target && target
      const targetToNull = prevProps.target && !target

      if (nullToTarget) {
        show()
      } else if (targetToNull) {
        hide()
      } else {
        hide()
        window.setTimeout(() => {
          window.clearTimeout()
          show()
        }, 450)
      }
    }
  }, [target])

  return (
    <div className="card-container">
      <div className="info-card" ref={cardRef} >
        <h1>{info.TITLE}</h1>
        {info.YEAR && <div className="year"><h2 className="text-shine">{info.YEAR}</h2></div>}
        <p>{info.BODY}</p>
        <div></div>
        <div className="icon-container">
          {info.KEYWORDS &&
            <div className="keywords">
              {info.KEYWORDS.map((k, i) => (<span key={i}>{k}</span>))}
            </div>}
          {info.URL && <a href={info.URL} target="_blank" rel="noopener noreferrer">
            <div className="info-url">
              Enter &nbsp; &nbsp; ↵
          </div>
          </a>}
          {info.ICON
            ? <div className="icon">{info.ICON()}</div>
            : <Fragment>{info.SOCIAL()}</Fragment>
          }
        </div>
      </div>
    </div>
  )
}
