import React, { useRef } from 'react'
import { extend, useFrame, useUpdate } from "react-three-fiber"
import { BufferAttribute } from 'three'
import { usePlane } from 'use-cannon'
import { WaterMaterial } from './shaders/custom-shader'

extend({ WaterMaterial })

const particles = 184

export function Plane(props) {
  const [ref] = usePlane(() => ({ mass: 0, ...props }))
  const webglRef = useRef()

  useFrame(state => {
    webglRef.current.userData.t.value = state.clock.elapsedTime
  })

  const geometry = useUpdate(geo => {
    const position = []
    const vert1 = []
    const vert2 = []
    const vert3 = []

    for (let x = 0; x < particles; x++) {
      for (let z = 0; z < particles; z++) {
        const no = [1 + x * 2, 0, -1 + z * 2]
        const nw = [-1 + x * 2, 0, -1 + z * 2]
        const so = [-1 + x * 2, 0, 1 + z * 2]
        const sw = [1 + x * 2, 0, 1 + z * 2]

        position.push(...nw); vert1.push(...nw); vert2.push(...so); vert3.push(...sw)
        position.push(...so); vert1.push(...nw); vert2.push(...so); vert3.push(...sw)
        position.push(...sw); vert1.push(...nw); vert2.push(...so); vert3.push(...sw)

        position.push(...sw); vert1.push(...sw); vert2.push(...no); vert3.push(...nw)
        position.push(...no); vert1.push(...sw); vert2.push(...no); vert3.push(...nw)
        position.push(...nw); vert1.push(...sw); vert2.push(...no); vert3.push(...nw)
      }
    }

    geo.setAttribute('position', new BufferAttribute(new Float32Array(position), 3))
    geo.setAttribute('vert1', new BufferAttribute(new Float32Array(vert1), 3))
    geo.setAttribute('vert2', new BufferAttribute(new Float32Array(vert2), 3))
    geo.setAttribute('vert3', new BufferAttribute(new Float32Array(vert3), 3))
  }, [])

  return (
    <mesh receiveShadow ref={ref}>
      <bufferGeometry ref={geometry} attach="geometry" />
      <waterMaterial
        attach="material"
        ref={webglRef}
        roughness={0.2}
        metalness={0.2}
        lights
      />
    </mesh>
  )
}
