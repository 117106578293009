import { Line } from "drei"
import React, { useMemo } from 'react'
import { Path } from 'three'

export function LifePath() {
  const points = useMemo(() => {
    const path = new Path()

    path.lineTo(3.5, 0)
    path.bezierCurveTo(6, 0, 8.5, -2.5, 8.5, -5)
    // path.lineTo(9, -5)

    path.lineTo(8.5, -25)
    path.bezierCurveTo(8.5, -27.5, 11, -30, 13.5, -30)
    // path.lineTo(14, -30)

    path.lineTo(23.5, -30)
    path.bezierCurveTo(26, -30, 28.5, -27.5, 28.5, -25)
    // // path.lineTo(29, -25)

    path.lineTo(28.5, 0)
    path.bezierCurveTo(28.5, 2.5, 31, 5, 33.5, 5)
    // // path.lineTo(34, 5)

    path.lineTo(43.5, 5)
    path.bezierCurveTo(46, 5, 48.5, 7.5, 48.5, 10)
    // path.lineTo(49, 10)

    path.lineTo(48.5, 50)
    path.bezierCurveTo(48.5, 52.5, 43.5, 52.5, 43.5, 50)

    path.bezierCurveTo(43.5, 47.5, 41, 47.5, 38.5, 47.5)
    path.lineTo(18.5, 47.5)

    const points2 = path.getPoints()
    return points2.map(v => [v.x, 0, v.y])
  }, [])

  return (
    <Line points={points} color="#ff2f00" linewidth={0.6} position={[29, 5.085, 42.5]} />
  )
}


export function GrayPath() {
  const points = useMemo(() => {
    const path = new Path()

    path.lineTo(0, 10)
    path.bezierCurveTo(0, 12.5, -2.5, 15, -5, 15)
    // path.lineTo(15, -5)

    path.lineTo(-15, 15)

    const points2 = path.getPoints()
    return points2.map(v => [v.x, 0, v.y])
  }, [])

  return (
    <Line points={points} color="#c9c9c9" linewidth={0.8} position={[57.5, 5.05, 42.5]} />
  )
}


export function GrayPath2() {
  const points = useMemo(() => {
    const path = new Path()
    path.lineTo(0, 20)
    path.bezierCurveTo(0, 22.5, -2.5, 25, -5, 25)

    path.lineTo(-10, 25)

    const points2 = path.getPoints()
    return points2.map(v => [v.x, 0, v.y])
  }, [])

  return (
    <Line points={points} color="#c9c9c9" linewidth={0.8} position={[57.5, 5.05, 52.5]} />
  )
}


export function GrayPath3() {
  const points = useMemo(() => {
    const path = new Path()

    path.bezierCurveTo(0, 2.5, 2.5, 5, 5, 5)
    path.lineTo(10, 5)

    const points2 = path.getPoints()
    return points2.map(v => [v.x, 0, v.y])
  }, [])

  return (
    <Line points={points} color="#c9c9c9" linewidth={0.8} position={[57.5, 5.05, 62.5]} />
  )
}
