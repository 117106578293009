import { MeshDistortMaterial } from 'drei'
import React, { useMemo } from 'react'
import { useLoader } from 'react-three-fiber'
import { CanvasTexture, TextureLoader } from 'three'
import { useBox } from 'use-cannon'

export function Project({ url, ...rest }) {
  const [texture] = useLoader(TextureLoader, [url])

  const [ref] = useBox(() => ({
    mass: 0.1,
    args: [7, 3.68, 0.1],
    position: rest.position,
    rotation: rest.rotation,
    type: 'Static',
  }))

  const speed = useMemo(() => rest.isActive ? 5.5 : 0, [rest.isActive])
  const map = useMemo(() => new CanvasTexture(texture), [texture])

  return (
    <mesh ref={ref} {...rest} >
      <planeGeometry attach="geometry" args={[7, 3.68]} />
      <MeshDistortMaterial
        map={texture}
        attach="material"
        // factor={10}
        speed={speed}
      />
    </mesh>
  )
}
