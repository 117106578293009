const MOBILE_AGENTS = [
  /Android/i,
  /webOS/i,
  /iPhone/i,
  /iPad/i,
  /iPod/i,
  /BlackBerry/i,
  /Windows Phone/i
]

export function detectMobile() {
  return MOBILE_AGENTS.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem)
  })
}
