const FIRST_VISIT = 'lifePath:firstVisit'

export function isFirstTimeUser() {
  const visit = JSON.parse(localStorage.getItem(FIRST_VISIT))
  return !visit
}

export function saveUserToLocalStorage() {
  localStorage.setItem(FIRST_VISIT, true)
}
