import React, { useEffect } from 'react'
import { useBox } from 'use-cannon'


const SIDE = 5
const HALF_SIDE = SIDE * 0.5
const NUDGE = 0.075
const UNIT_DIMENSIONS = [SIDE - NUDGE, SIDE, SIDE - NUDGE]

const TERRAIN_MAP = [
  { r1: [6, 7] },
  { r1: [2, 4], r2: [6, 12] },
  { r1: [1, 12] },
  { r1: [0, 11] },
  { r1: [0, 12] },
  { r1: [0, 16] },
  { r1: [0, 17] },
  { r1: [2, 19] },
  { r1: [3, 19] },
  { r1: [3, 18] },
  { r1: [5, 18] },
  { r1: [7, 18] },
  { r1: [7, 18] },
  { r1: [9, 18] },
  { r1: [8, 16] },
  { r1: [8, 16] },
  { r1: [8, 17] },
  { r1: [8, 17] },
  { r1: [8, 18] },
  { r1: [7, 10], r2: [13, 18] },
  { r1: [7, 9], r2: [13, 17] },
  { r1: [8, 9], r2: [15, 17] },
]

export function Unit({ number }) {
  const [ref, api] = useBox(() => ({
    mass: 0.1,
    args: UNIT_DIMENSIONS,
    position: [0, 0, 0],
    type: 'Static'
  }))

  useEffect(() => {
    let k = 0

    TERRAIN_MAP.forEach((row, index) => {
      Object.keys(row).forEach(range => {
        const currentRange = row[range]
        const start = currentRange[0]
        const end = currentRange[1]

        const count = end - start
        const z = index * SIDE + HALF_SIDE
        for (let m = 0; m <= count; m++) {
          const x = (start + m) * SIDE + HALF_SIDE
          api.at(k).position.set(x, HALF_SIDE, z)
          k++
        }

      })
    })
    ref.current.matrixAutoUpdate = false
    ref.current.updateMatrix()
  }, [api])

  return (
    <instancedMesh receiveShadow castShadow ref={ref} args={[null, null, number]}>
      <boxBufferGeometry attach="geometry" args={UNIT_DIMENSIONS} />
      <meshStandardMaterial attach="material" color="white" />
    </instancedMesh>
  )
}
